import React from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { useTranslation } from 'react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import '../assets/scss/components/_telegram.scss'; // Import your styles

const PopupDownloadLink = ({ downloadLink, children }) => {
  const { t } = useTranslation();

  const handleDownload = () => {
    // Open the file in a new tab
    window.open(downloadLink, '_blank');
  };

  return (
    <Popup
      trigger={children}
      position="center center" // Center the modal
      modal // Ensure this is set to true for modal behavior
      closeOnDocumentClick={true} // Enable closing when clicking outside
      className="custom-popup"
      onClose={handleDownload} // Trigger download on close (outside click or close button)
    >
      {close => (
        <div className="TelegramComponent">
          <a
            className="popup-close popup-close-top"
            onClick={() => {
              handleDownload(); // Trigger download
              close(); // Close the popup
            }}
          >
            &times;
          </a>
          <a
            className="TelegramComponent-image-link"
            href="https://t.me/quranplayermp3"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StaticImage
              src="../assets/images/Join-us-on-telegram.png"
              alt="banner"
              layout="constrained"
              width={400}
            />
          </a>
          <a
            className="popup-close popup-close-bottom"
            onClick={() => {
              handleDownload(); // Trigger download
              close(); // Close the popup
            }}
          >
            &times;
          </a>
          <h2 className="TelegramComponent-Title">
            {t('Download Quran')}
          </h2>
          <div className="TelegramComponent-text">
            {t("Click to access the content.")}
          </div>
          <a
            className="TelegramComponent-href"
            href="https://t.me/quranplayermp3"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('Download')}
          </a>
        </div>
      )}
    </Popup>
  );
};

export default PopupDownloadLink;
