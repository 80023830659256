import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Header from "./Header";
import Footer from "./Footer";
import NewsletterSubscription from "./NewsletterSubscription";
import SearchPopup from "./SearchPopup";
import SearchPopupContext from "../util/searchPopupContext";
import ShareLinks from "./ShareLinks";
import BottomBanner from "../templates/bottomBanner";
import TelegramComponent from "./telegram";
import TelegramPopup from "./TelegramPopup"; // Import TelegramPopup

const Layout2 = ({ children, pageTitle }) => {
  const [popupVisible, setPopupVisible] = React.useState(false);
  const [telegramPopupOpen, setTelegramPopupOpen] = React.useState(false);

  const { site, markdownRemark } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          stickyNav
        }
      }
      markdownRemark {
        frontmatter {
          title
        }
      }
    }
  `);
  const { stickyNav } = site.siteMetadata;
  const currentArticleTitle = pageTitle || markdownRemark.frontmatter.title;

  React.useEffect(() => {
    // Check localStorage to see if the popup was previously closed
    const isPopupClosed = localStorage.getItem('telegramPopupClosed');

    if (!isPopupClosed) {
      // Show the popup after 5 seconds if it hasn't been closed before
      const timer = setTimeout(() => {
        setTelegramPopupOpen(true);
      }, 30000);

      // Cleanup the timer
      return () => clearTimeout(timer);
    }
  }, []);

  const handlePopupClose = () => {
    // Set a flag in localStorage to prevent popup from opening again
    localStorage.setItem('telegramPopupClosed', 'true');
    setTelegramPopupOpen(false);
  };

  return (
    <div className="site-wrap" data-nav={stickyNav ? "sticky" : ""}>
      <SearchPopupContext.Provider value={{ popupVisible, setPopupVisible }}>
        <Header />
        {children}
        {typeof window !== 'undefined' && (
          <ShareLinks url={window.location.href} title={`${currentArticleTitle}`} />
        )}
        <TelegramComponent />
        <NewsletterSubscription />
        <BottomBanner />
        <Footer />
        <SearchPopup />

        {/* Add TelegramPopup component here */}
        <TelegramPopup
          isOpen={telegramPopupOpen}
          onClose={handlePopupClose} // Handle popup close
        />
      </SearchPopupContext.Provider>
    </div>
  );
};

export default Layout2;
